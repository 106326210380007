<template>
  <v-card>


    <v-card
      flat
      color="primary"
      dark
      class="text-uppercase text-center py-1"
      style="font-weight: 500; position: absolute; right: 0; border-top-left-radius: 0; border-bottom-right-radius: 0; width: 45px;"
    >
      {{ translation.language }}
    </v-card>


    <v-card
      v-if="showTranslationResponse"
      flat
      dark
      transition="slide-x-transition"
      style="position: absolute; right: 0; z-index: 5; border-top-left-radius: 0; border-bottom-right-radius: 0;"
      :color="translationResponseColor"
    >
      <v-card-text
        style="font-weight: 500; font-size: 1rem; color: #FFFFFF;"
        class="align-content-center fill-height"
      >
        {{ translationResponseMessage }}
      </v-card-text>
    </v-card>



    <v-card-title class="pb-0 pt-2">
      <span><span style="font-weight: 500; font-size: 16px;">{{ translationHeader }}</span> <span
        class="text-capitalize"
        style="font-weight: 400; font-size: 16px;"
      >{{ translation.key }}</span></span>
    </v-card-title>
    <v-container class="px-5 py-1">
      <v-row align="center">
        <v-col
          cols="12"
          md="4"
          v-if="false"
        >
          <div class="text-capitalize caption">
            {{ translation.key }}
          </div>
          <div>{{ translation.item[translation.key] }}</div>
        </v-col>
        <v-col
          cols="12"
          md="5"
        >
          <v-textarea
            v-if="showTextarea && false"
            :value="actualItemValue"
            outlined
            hide-details
            :label="capitalize(translation.key)"
            disabled
          />
          <TipTapRichTextEditor
            v-if="showTextarea"
            :value="actualItemValue"
            flat
            outlined
            disabled
          />
          <v-text-field
            v-else
            :value="actualItemValue"
            outlined
            hide-details
            :label="capitalize(translation.key)"
            disabled
          />
        </v-col>
        <v-col
          cols="12"
          md="auto"
          v-if="$vuetify.breakpoint.mdAndUp"
          class="text-center"
        >
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                v-bind="attrs"
                v-on="on"
                @click="copyFromItem"
              >
                <v-icon>mdi-arrow-right-bold-outline</v-icon>
              </v-btn>
            </template>
            <span>{{ $t('copy') }}</span>
          </v-tooltip>
        </v-col>
        <v-col
          cols="12"
          md="5"
        >
          <v-textarea
            v-if="showTextarea && false"
            v-model="translation.value"
            outlined
            hide-details
            :label="capitalize(translation.key)"
          />
          <TipTapRichTextEditor
            v-if="showTextarea"
            v-model="translation.value"
            flat
            outlined
          />
          <v-text-field
            v-else
            v-model="translation.value"
            outlined
            hide-details
            :label="capitalize(translation.key)"
          />
        </v-col>
        <v-col
          cols="12"
          md="auto"
          class="grow"
        >
          <v-btn
            large
            block
            color="primary"
            @click="saveTranslation"
            :loading="savingTranslation"
            :disabled="savingTranslation"
          >
            <span>{{ $t('saveTranslation') }}</span>
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import TipTapRichTextEditor from "@/components/common/TipTapRichTextEditor"
export default {
  name: "TranslationCard",
  components: {TipTapRichTextEditor},
  props: {
    translation: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      savingTranslation: false,
      showTranslationResponse: false,
      translationResponseColor: null,
      translationResponseMessage: null,
    }
  },
  methods: {
    responseAction(response) {
      this.showTranslationResponse = true
      if(response === true) {
        this.translationResponseColor = 'success'
        this.translationResponseMessage = this.$t('translationSaved')
      } else {
        this.translationResponseColor = 'error'
        this.translationResponseMessage = this.$t('couldNotSaveTranslation')
      }
      setTimeout(() => {
        this.showTranslationResponse = false
        this.translationResponseColor = null
        this.translationResponseMessage = null
      }, 3000)
    },
    saveTranslation() {
      this.savingTranslation = true
      this.$store.dispatch('updateTranslation', this.translation).then(response => {
        this.responseAction(response)
      }).finally(() => {
        this.savingTranslation = false
      })
    },
    capitalize(text) {
      if(text !== null && text !== undefined) {
        return text.slice(0, 1).toUpperCase() + text.slice(1)
      }
      return ''
    },
    copyFromItem() {
      this.translation.value = this.translation.item[this.translation.key]
    }
  },
  computed: {
    showTextarea() {
      return this.translation.key === 'description' || this.translation.key === 'description.value' || this.translation.key === 'description.summary' || this.translation.key === 'summary' || this.translation.key === 'description:value' || this.translation.key === 'description:summary'
    },
    whitelistedProperties() {
      return [
        { key: 'title', value: 'title' },
        { key: 'name', value: 'name' },
        { key: 'label', value: 'label'},
        { key: 'description', value: 'description' },
        { key: 'description.value', value: 'description:value' },
        { key: 'description.summary', value: 'description:summary' }
      ]
    },
    actualItemValue() {
      if(typeof this.translation.item[this.translation.key] === 'object') {
        if(this.translation.key === 'description' || this.translation.key === 'description:value') {
          if(this.translation.item && this.translation.item.description && this.translation.item.description.value) {
            return this.translation.item.description.value
          }
          return ''
        }
        if(this.translation.key === 'summary' || this.translation.key === 'description:summary') {
          if(this.translation.item && this.translation.item.description && this.translation.item.description.summary) {
            return this.translation.item.description.summary
          }
          return ''
        }
      }

      return this.translation.item[this.translation.key]
    },
    translationHeader() {
      try {
        if(this.translation.type === 'category' || this.translation.type === 'attribute') {
          return this.translation.item.name
        }
        if(this.translation.type === 'item') {
          return this.translation.item.title
        }
        if(this.translation.type === 'variation') {
          return this.translation.item.label
        }
      } catch (e) {
        return ''
      }
      return ''
    }
  }
}
</script>

<style scoped>

</style>
